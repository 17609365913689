<template>
  <b-badge class="no-select" :id="'id'+_uid" variant="light">
    <font-awesome-icon
        class="h6 m-0 ml-1"
        v-for="label in globalData.labels"
        :key="label.id"
        :icon="getLabelIcon(label)"
        :color="getLabelColor(label)"
        @click="onLabelChange(label, ! selectedLabelUuids.includes(label.uuid))"
    />
    <b-popover :target="'id'+_uid" triggers="hover" placement="top" delay="500">
      <h6>
        <b-badge class="no-select ml-1 mr-1" v-for="label in globalData.labels" :key="label.id" variant="light">
          <font-awesome-icon
              :icon="getLabelIcon(label)"
              :color="getLabelColor(label)"
          />
          {{ label.text }}
        </b-badge>
      </h6>
    </b-popover>
  </b-badge>
</template>

<script>

import LabelMixin from "./LabelMixin";

export default {
  name: 'LabelSelector',
  mixins: [LabelMixin],
}
</script>