<template>
  <b-card>
    <b-card-title class="text-left">Garmin Connect™</b-card-title>
    <b-card-sub-title v-if="user" class="text-left"><strong>{{ globalData.activeUser.name }}</strong> ({{ globalData.activeUser.username }})
    </b-card-sub-title>
    <b-card-body>

      <template v-if="connected">
        <b-card-text class="text-left">
          You are connected to <a href="https://connect.garmin.com/" target="_blank">
          <strong>Garmin Connect™</strong></a>.
        </b-card-text>
        <b-card-text>
          <b-button v-b-hover="hoverHandler" @click="disconnect" :variant="isHovered ? 'danger' : 'success'" size="sm">{{
              isHovered ? 'Disconnect' : 'Connected'
            }}
          </b-button>
        </b-card-text>
      </template>

      <template v-else>
        <b-card-text class="text-left">You are not connected with Garmin Connect™.</b-card-text>
        <b-card-text>
          <b-button @click="connect" variant="primary" size="sm">Connect</b-button>
        </b-card-text>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import {getAuth} from 'firebase/auth';

export default {
  name: 'GarminConnector',
  props: {
    globalData: Object,
  },
  data() {
    return {
      user: Object,
      connected: false,
      isHovered: false,
    }
  },
  watch: {
    globalData: {
      handler() {
        this.$http.get(`/api/garmin/validate`)
            .then(response => {
              this.connected = response.data.valid
            })
            .catch(e => {
              this.connected = false;
              console.log(e)
            })
      },
      deep: true
    },
  },
  methods: {
    hoverHandler(isHovered) {
      this.isHovered = isHovered;
    },
    connect() {
      // TODO: refresh when expired
      getAuth().currentUser.getIdToken(false).then(function (idToken) {
        window.open(`/api/submit?id_token=${idToken}`,
            'newwindow',
            'width=500,height=1000');
      }).catch(function (e) {
        console.warn(e)
      });
    },
    disconnect() {
      this.$http.post(`/api/garmin/invalidate`)
          .then(() => {
            this.connected = false;
          })
          .catch(e => {
            console.warn(e)
          })
    }
  },
}
</script>

<style scoped>
</style>
