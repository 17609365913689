<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>

    <b-row class="text-left">
      <b-col>
        <h2>Users and Teams</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="sitemap" size="1x"/>
          {{ globalData.currentOrganization.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Users and Teams"></b-form-input>
      </b-col>
      <b-col cols="4">
        <b-form-checkbox class="text-right" v-model="hideUsersWithTeams" size="lg" switch>Hide Users with Teams
        </b-form-checkbox>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        :items="filteredUsers"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-compare="compareTeams"
    >
      <template #cell(teams)="data">
        <b-dropdown
            size="sm"
            :text="getUserDropDownText(data.item.teamCount)"
            class="m-0 w-50"
            :variant="getUserDropDownVariant(data.item.teamCount)"
            boundary="window">
          <b-dropdown-form>
            <b-form-checkbox
                size="sm"
                v-for="team in teams"
                :key="team.uuid"
                :checked="isUserAssigned(data.item.groups, team.uuid)"
                @change="onChange($event, data.item.uuid, team.uuid)"
            >
              {{ team.name }}
            </b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
      </template>
    </b-table>

  </b-container>
</template>

<script>

import ForbiddenBanner from "../components/ForbiddenBanner";
import ForbiddenContentMixin from "../components/ForbiddenContentMixin";

export default {
  name: 'UsersAndTeams',
  mixins: [ForbiddenContentMixin],
  components: {
    ForbiddenBanner,
  },
  data() {
    return {
      data: [],
      teams: [],
      hideUsersWithTeams: false,
      sortBy: 'teams',
      sortDesc: false,
      fields: [
        {key: "name", sortable: true, class: "text-truncate"},
        {key: "username", sortable: true, class: "text-truncate"},
        {key: "teams", sortable: true, class: "text-truncate"},
      ],
      filter: '',
    }
  },
  props: {
    q: String,
  },
  computed: {
    filteredUsers: function () {
      let self = this;
      if (!this.data) {
        return [];
      }
      return this.data.filter(function (user) {
        return (user.teamCount === 0 || !self.hideUsersWithTeams);
      })
    }
  },
  created() {
    this.filter = this.q;
    this.refresh();
  },
  methods: {
    compareTeams(userA, userB, key) {
      if (key === 'teams') {
        return Math.sign(userA.teamCount - userB.teamCount);
      }
      return null;
    },
    refresh() {
      this.$http.get(`/api/admin/users-and-groups`)
          .then(response => {
            this.forbidden = false;
            this.data = response.data
            response.data.forEach(item => {
              item.teamCount = Object.keys(item.groups).filter(value => Object.keys(this.teams).includes(value)).length;
            })
          })
          .catch(e => {
            this.HandleResponse(e);
          })

      // This end point is for different purpose, however since the current user is Admin on the org,
      // we know it will return all the relevant teams
      this.$http.get(`/api/groups/teams`)
          .then(response => {
            this.teams = {};
            response.data.teams.forEach(item => {
              this.teams[item.group.uuid] = item.group;
            })
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
    onChange(value, userUuid, teamUuid) {
      const data = {
        teamUuid: teamUuid,
        userUuid: userUuid,
        value: value,
        role: 'USER',
      }
      this.$http.post(`/api/admin/assign`, data)
          .then(() => {
            this.refresh();
          })
          .catch(e => {
            console.warn(e);
          })
    },
    isUserAssigned(userGroups, teamUuid) {
      return Object.keys(userGroups).includes(teamUuid);
    },
    getUserDropDownText(teamCount) {
      return teamCount === 0 ? "No Teams" : teamCount === 1 ? "1 Team" : teamCount + " Teams";
    },
    getUserDropDownVariant(teamCount) {
      return teamCount === 0 ? "warning" : "outline-success";
    }
  }
}
</script>

<style scoped>

</style>
