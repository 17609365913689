<template>
  <b-container>
    <b-row class="text-left">
      <b-col>
        <h2>{{ workoutName }}&#8205;</h2>
        <h5 class="text-secondary">{{ globalData.currentOrganization.name }}</h5>
      </b-col>
    </b-row>
    <br/>
    <b-row>
      <b-col>
        <b-card class="text-left">
          <b-card-title>Workout Code</b-card-title>
          <b-card-sub-title>Workout labels:
            <LabelSelector
                :globalData="globalData"
                :readOnly="false"
                :selectedLabelUuids="labelUuids"
                v-on:selected-labels-changes="onEdit"
            />
          </b-card-sub-title>
          <b-card-body>
            <b-form-textarea
                id="yaml"
                v-model="workout_yaml"
                rows="20"
                @input="onEdit"
            ></b-form-textarea>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <WorkoutCard :uuid="uuid" :compact="true" class="d-none d-xl-block"/>
        <WorkoutCard :uuid="uuid"/>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import WorkoutCard from "../components/Workouts/WorkoutCard";
import LabelSelector from "../components/Labels/LabelSelector";

export default {

  name: 'Workout',
  components: {
    WorkoutCard,
    LabelSelector,
  },
  props: {
    uuid: String,
    globalData: Object
  },
  data() {
    return {
      workout_yaml: '',
      labelUuids: [],
      workoutName: "",
      // duration: Number,
      // steps: [],
    }
  },
  created() {
    this.$http.get(`/api/workouts/${this.uuid}.yaml`)
        .then(response => {
          this.workout_yaml = response.data
        })
        .catch(e => {
          console.warn(e)
        })

    this.$http.get(`/api/workouts/${this.uuid}.json`)
        .then(response => {
          this.labelUuids = response.data.meta.labelUuids ? response.data.meta.labelUuids : [];
          this.workoutName = response.data.name;
        })
        .catch(e => {
          console.warn(e)
        })
  },
  methods: {
    onEdit: function () {
      console.debug(this.workout_yaml);

      this.$http.post(`/api/workouts/${this.uuid}/save`, {
            workout_yaml: this.workout_yaml,
            meta: {
              labelUuids: this.labelUuids,
            },
          }
      )
          .then(response => {
            console.debug(response.data.workout)
            this.$emit('updateWorkout', response.data);

          })
          .catch(e => {
            console.warn(e)
          })
    }
  }
}
</script>
<style scoped>

#yaml {
  font-family: "Courier New";
  font-weight: bold;
  font-size: 14px;
}

.container {
  margin-top: 30px;
}
</style>
