<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Users and Roles</h2>
        <h5 class="text-secondary">Entire Database</h5>
      </b-col>
      <b-button v-b-toggle.collapse-1 variant="link">Roles
        <font-awesome-icon
            icon="info-circle"
            size="1x"
        />
      </b-button>
    </b-row>
    <!-- Collapse with textual explanation about the roles -->
    <b-collapse id="collapse-1" class="mt-2 mb-3">
      <b-card>
        <div v-for="item in roleInfo" :key="item.name">
          <h5>
            <b-badge :variant="roleToVariant(item.name)">{{ item.name }}</b-badge>
          </h5>
          <ul>
            <li>{{ item.text1 }}</li>
            <li>{{ item.text2 }}</li>
          </ul>
        </div>
      </b-card>
    </b-collapse>

    <UsersAndRolesTable
        :data="data"
        :global-data="globalData"
        :is-super-admin="isSuperAdmin()"
        v-on="$listeners"
    />
    <!--
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Users and Roles"></b-form-input>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        :items="data"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
    >
      <template #cell(role)="data">
        <h5>
          <b-badge :variant="roleToVariant(data.item.role)">{{ data.item.role }}
          </b-badge>
        </h5>
      </template>
      <template #cell(groupType)="data">
        <h5>
          <b-badge :variant="groupTypeToVariant(data.item.groupType)">{{ data.item.groupType }}
          </b-badge>
        </h5>
      </template>
      <template #cell(actions)="data">
        <b-button
            v-if="isSuperAdmin() && (data.item.username != globalData.activeUser.username)"
            size="sm"
            title="Impersonate"
            variant="outline-danger"
            @click="onImpersonate(data.item)">
          <font-awesome-icon icon="mask"/>
        </b-button>
      </template>

    </b-table>
    -->
  </b-container>
</template>

<script>

import ForbiddenBanner from "../../components/ForbiddenBanner";
import ForbiddenContentMixin from "../../components/ForbiddenContentMixin";
import UsersAndRolesTable from "../../components/Admin/UsersAndRolesTable"

export default {
  name: 'UsersAndRolesView',
  mixins: [ForbiddenContentMixin],
  components: {
    UsersAndRolesTable,
    ForbiddenBanner,
  },
  data() {
    return {
      data: [],
      sortBy: 'role',
      sortDesc: false,
      fields: [
        {key: "name", sortable: true, class: "text-truncate"},
        {key: "username", sortable: true, class: "text-truncate"},
        {key: "role", sortable: true, class: "text-truncate"},
        {key: "groupName", sortable: true, class: "text-truncate"},
        {key: "groupType", sortable: true, class: "text-truncate"},
        {key: "actions", label: "", class: "d-none d-md-table-cell"},
      ],
      roleInfo: [
        {
          name: 'User',
          text1: 'Assigned to team(s)',
          text2: 'Consumes workouts, can see their own profile and team workouts'
        },
        {
          name: 'Coach',
          text1: 'Assigned to team(s)',
          text2: 'Manages and published workouts, view users in the teams'
        },
        {
          name: 'Admin',
          text1: 'Assigned to organization(s)',
          text2: 'Can add and remove users to the org and teams, assign roles'
        },
        {
          name: 'Owner',
          text1: 'Assigned to organization(s). Similar to Admin',
          text2: 'Special admin that cannot be removed from the organization'
        },
        {
          name: 'SuperAdmin',
          text1: 'Assigned to the global group',
          text2: 'Full access to everything'
        },
      ],
      filter: '',
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$http.get(`/api/sa/users-and-roles`)
          .then(response => {
            this.forbidden = false;
            this.data = response.data
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
    roleToVariant(role) {
      switch (role) {
        case 'SuperAdmin':
          return 'dark'
        case 'Owner':
          return 'danger'
        case 'Admin':
          return 'warning'
        case 'Coach':
          return 'success'
        case 'User':
          return 'primary'
      }
      return 'light'
    },
    groupTypeToVariant(groupType) {
      switch (groupType) {
        case 'Organization':
          return 'primary'
        case 'Team':
          return 'success'
      }
      return 'light'
    },
    onImpersonate: function (user) {
      console.info(`Impersonating to ${user.uuid}`)
      this.$http.get(`/api/sa/users/${user.uuid}/debug`)
          .then(response => {
            console.error(response.data);
          })
          .catch(e => {
            console.warn(e)
          })

      this.$http.post(`/api/sa/impersonate`,
          {},
          {
            headers: {
              'Impersonate-User': user.uuid,
            }
          })
          .then(() => {
            this.$emit('active-user-changed');
            this.$router.push({name: 'profile'});
          })
          .catch(e => {
            console.warn(e)
          })
    },
  }
}
</script>
