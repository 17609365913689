<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Organizations</h2>
        <h5 class="text-secondary">Entire Database</h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Organizations"></b-form-input>
      </b-col>
      <b-col cols="4">
      </b-col>
      <b-col cols="3" align-h="end" class="text-right">
        <b-button variant="success" lass="text-right" @click="onCreateOrganization">Create</b-button>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        :items="groups"
        :fields="fields"
        :filter="filter"
    >
      <template #cell(name)="data">
        <b-link :to="{ name: 'organization', params: { uuid: data.item.uuid }}">{{ data.item.name }}</b-link>
      </template>
      <template #cell(actions)="data">
        <b-button size="sm" variant="outline-danger" @click="onDeleteOrganization(data.item)">Delete
          <font-awesome-icon icon="trash-alt" size="1x"
          />
        </b-button>
      </template>
    </b-table>

  </b-container>
</template>

<script>

import ForbiddenBanner from "../../components/ForbiddenBanner";
import ForbiddenContentMixin from "../../components/ForbiddenContentMixin";

export default {
  name: 'Organizations',
  mixins: [ForbiddenContentMixin],
  components: {
    ForbiddenBanner,
  },
  data() {
    return {
      groups: Array,
      fields: [
        {key: "name", sortable: true, class: "text-truncate"},
        {key: 'actions', sortable: false, label: ''},
      ],
      filter: '',
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$http.get(`/api/groups/?type=Organization`)
          .then(response => {
            this.groups = response.data;
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
    onCreateOrganization() {
      this.$http.post(`/api/groups/`, {
        name: 'Group Name',
        type: 'ORGANIZATION'
      })
          .then(() => {
            this.refresh();
          })
          .catch(e => {
            console.log(e);
          })
    },
    onDeleteOrganization(organization) {

      this.$bvModal.msgBoxConfirm(
          `Are you sure you want to delete ${organization.name}?`, {
            title: `Delete ${organization.name}`,
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes, Delete',
            cancelTitle: 'No, Keep It',
            centered: true
          })
          .then(value => {
            if (value) {

              this.$http.delete(`/api/groups/${organization.uuid}`,)
                  .then(() => {
                    this.refresh();
                    this.groupUuid = ''
                  })
                  .catch(e => {
                    console.log(e);
                  })
            }
          })
          .catch(e => {
            console.log(e);
          })


    }
  },

}
</script>

<style scoped>
</style>
