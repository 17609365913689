<template>
  <!-- Slots -->
  <b-container class="text-center">
    <b-row class="text-left">
      <b-col>
        <h2>{{ plan.name }}&#8205;</h2>
        <h5 class="text-secondary">{{ globalData.currentOrganization.name }}</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
          <b-col md="7" lg="5">
            <b-form-input v-model="filter" placeholder="Search Workouts"></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-form-checkbox class="text-right" v-model="showInactive" size="lg" switch>Show inactive</b-form-checkbox>
          </b-col>
          <b-col cols="3" align-h="end" class="text-right">
            <b-button variant="success" lass="text-right" @click="onPublish()">Publish</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <b-row class="text-center mt-2 scrollable-column" style="z-index: -1;">
          <b-col v-for="workout in filteredWorkouts" :key="workout.id" cols="6" class="workout-card p-1 mb-4">
            <drag :transfer-data="{ uuid: workout.uuid, name: workout.name }">
              <WorkoutCard
                  :uuid="workout.uuid"
                  :compact="true"
                  :show-link="true"></WorkoutCard>
            </drag>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-col v-for="slot in plan.slots" :key="`slot-${slot.uuid}`"
               class="mt-3 mb-0 p-2 border shadow-lg user-select-none bg-light">
          <drop class="drop" :id="`slot-${slot.id}`" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
            <b-overlay :show="slot.showOverlay" rounded="sm">
              <b-card class="text-left mt-0 mb-0" :class="{ 'bg-secondary': dropTarget == `slot-${slot.id}` }"
                      body-class="pt-1 pb-0" no-body>
                <b-card-header class="h6 pt-2 pb-1" :class="{ 'bg-secondary': dropTarget == `slot-${slot.id}` }"
                               :header-class="slot.workoutUuid !== null ? 'assigned-slot-header' : ''">
                  <b-row>
                    <b-col cols="10">{{ slot.name }}</b-col>
                    <b-col cols="2" class="text-right" v-show="slot.workoutUuid !== null" @click="clearSlot(slot.id)">
                      <font-awesome-icon icon="trash-alt" size="1x" id="icon"/>
                    </b-col>
                  </b-row>
                </b-card-header>

                <b-card-body class="p-0">
                  <WorkoutCard v-if="slot.workoutUuid !== null" :uuid="slot.workoutUuid" :compact="true"/>
                  <div v-else class="slot text-center text-secondary align-middle pt-2 font-italic font-weight-light">
                    <small>Drop Workout Here</small>
                  </div>
                </b-card-body>
              </b-card>
            </b-overlay>
          </drop>
        </b-col>

        <!-- <b-row v-for="slot in ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.']" :key="slot"
          class="m-2 p-2 border shadow-lg user-select-none bg-light" style="height: 100px">
          <drop class="drop" :id="`slot-${slot}`" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
            {{ slot }}
          </drop>
        </b-row> -->
      </b-col>
    </b-row>

  </b-container>

</template>

<script>
// import {Drag, Drop} from 'vue-drag-drop';
import WorkoutCard from "@/components/Workouts/WorkoutCard";

export default {

  name: 'Test',
  components: {
    // Drag, Drop,
    WorkoutCard
  },
  props: {
    uuid: String,
    globalData: Object
  },
  watch: {
    globalData: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
  data() {
    return {
      // The current drop target
      title: 'This Week',
      planUuid: String,
      dropTarget: Element,
      showInactive: false,
      plan: Object,
      workouts: [],
      weekdays: Array,
      date: Date,
      filter: '',
    }
  },
  computed: {
    filteredWorkouts: function () {
      let self = this;
      if (!this.workouts) {
        return [];
      }
      return this.workouts.filter(function (workout) {
        return (workout.active || self.showInactive) && workout.name.toUpperCase().indexOf(self.filter.toUpperCase()) >= 0;
      })
    }
  },
  created() {
    this.weekdays = [];
    this.plan = {
      slots: [],
    }
  },
  mounted() {
    this.planUuid = this.uuid;

    this.dropTarget = null;
    let offset = 0;  // Use for changing 'today'

    this.date = new Date();
    this.date.setDate(this.date.getDate() + offset);
    this.date.setHours(0, 0, 0, 0);
    let dayOfWeek = this.date.getDay()
    if (dayOfWeek >= 5) {
      // Starting on Friday - show the next week
      this.title = 'Next Week'
      dayOfWeek -= 7;
    }

    let sunday = new Date();
    sunday.setUTCHours(0, 0, 0, 0);
    sunday.setDate(this.date.getDate() - dayOfWeek);

    let weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    for (let i = 0; i < 7; i++) {
      let day = new Date(sunday.getTime());
      day.setDate(sunday.getDate() + i);
      day.setUTCHours(0, 0, 0, 0);

      this.weekdays.push({
        key: i,
        date: day,
        dayOfWeek: weekdays[i],
        dayOfMonth: day.getDate(),
        today: dayOfWeek == i,
      });
    }
    this.refresh();
  },
  methods: {
    refresh: function () {
      this.$http.get(`/api/plans/${this.planUuid}`)
          .then(response => {
            this.plan = response.data;
            let i = 0;
            this.plan.slots.forEach(slot => {
              slot.id = i++;
              slot.showOverlay = false;

              this.$http.get(`/api/workouts`)
                  .then(response => {
                    this.workouts = response.data;
                    let i = 0;
                    this.workouts.forEach(workout => {
                      workout.id = i++;
                    })
                  })
                  .catch(e => {
                    console.error(e)
                    this.workouts = []
                  })
            })
          })
          .catch(e => {
            if (e.response.status === 403) {
              // User is not allowed to see this plan. Send him to the Plans page
              this.$router.push({name: 'plans'})
            } else {
              console.error(e);
              this.plan.slots = []
            }
          })
    },
    makeToast(title = '', content = '', variant = 'success') {
      this.$bvToast.toast(content, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-center',
        autoHideDelay: 100,
        noCloseButton: true,
      })
    },
    onPublish() {
      this.makeToast('Publish', 'Publishing the plan');
      console.info(`Publish Plan ${this.planUuid}`);
      this.$http.post(`/api/plans/${this.planUuid}/publish`, {'firstDayOfWeek': this.weekdays[0].date.getTime()})
          .then(() => {

          })
          .catch(e => {
            console.error(e)
            this.plan.slots = []
          })

    },
    onDragOver(data, event) {
      this.dropTarget = event.currentTarget.id;
    },
    onDragLeave(/*data, event*/) {
      this.dropTarget = null;
    },
    onDrop(data, event) {
      this.dropTarget = null;
      let slotId = event.currentTarget.id;
      let index = parseInt(slotId.replace('slot-', ''));
      let targetSlot = this.plan.slots[index]
      let slotUuid = targetSlot.uuid

      let self = this;
      targetSlot.showOverlay = true
      targetSlot.workoutUuid = data.uuid

      this.$http.post(`/api/plans/${this.planUuid}/slots/${slotUuid}/assign`, {workoutUuid: data.uuid})
          .then(() => {
            console.debug(`Assigning workout id ${data.workoutUuid} to slot ${slotUuid}`);
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            targetSlot.showOverlay = false;
            self.$forceUpdate();
          })

    },
    clearSlot(slotIndex) {
      let slot = this.plan.slots[slotIndex]
      let slotUuid = slot.uuid
      let self = this;
      this.$http.post(`/api/plans/${this.planUuid}/slots/${slotUuid}/assign`, {workoutUuid: null})
          .then(() => {
            console.debug(`Unassigning slot ${slotUuid}`);
            slot.workoutUuid = null;
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            // targetSlot.showOverlay = false;
            self.$forceUpdate();
          })
    },
  },
}
</script>
<style scoped>

.workout-card {
  height: 110px;
}

.slot {
  height: 50px;
}

.assigned-slot-header {
  background-color: #42b983;
}

.weekday {
  height: 0;
  width: 75px;
  padding-bottom: 75px;
  border-radius: 15px;
  border: 2px black solid;
  justify-content: center;
  align-items: center;
}

.scrollable-column {
  height: 75vh;
  overflow-y: scroll;
}
</style>
