<template>
  <span id="duration">
    <font-awesome-icon icon="times-circle" size="1x"/>
    &nbsp;<strong>{{ repetitions }} </strong>
  </span>
</template>

<script>

export default {
  name: 'Distance',
  props: {
    repetitions: Number
  },
}
</script>

<style scoped>

</style>
