import Vue from 'vue'
import VueRouter from 'vue-router'

import {getAuth} from 'firebase/auth';

import Workout from "../views/Workout";
import Workouts from "../views/Workouts";
import Runners from "../views/Runners";
import Home from "../views/Home";
import Login from "../views/Login";
import Plan from "../views/Plan";
import PlanList from "../views/PlanList";
import Profile from "../views/Profile";
import WeeklyPlan from "../views/WeeklyPlan";
import Test from "../views/Test";
import UsersAndTeams from "../views/UsersAndTeams";
import UserActivities from "../views/UserActivities";
import Join from "../views/Join";
import LabelView from "@/views/LabelView";
import MyOrganizationView from "@/views/MyOrganizationsView";
import UsersAndRolesView from "../views/super-admin/UsersAndRolesView";
import Organizations from "../views/super-admin/Organizations";
import Organization from "../views/super-admin/Organization";
import Jobs from "../views/super-admin/Jobs";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'home',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/runners',
        name: 'runners',
        component: Runners,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/runners/:uuid',
        name: 'runner',
        component: Home
    },
    {
        path: '/labels',
        name: 'labels',
        component: LabelView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/workouts',
        name: 'workouts',
        component: Workouts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/workouts/:uuid',
        name: 'workout',
        component: Workout,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/plans',
        name: 'plans',
        component: PlanList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/plans/:uuid',
        name: 'plan',
        component: Plan,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/plans/:uuid/assign-workouts',
        name: 'assign-workouts',
        component: WeeklyPlan,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users/:uuid/activities',
        name: 'user-activities',
        component: UserActivities,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/join',
        name: 'join',
        component: Join,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/test',
        name: 'test',
        component: Test,
        meta: {
            requiresAuth: true
        }
    },
    // Admin
    {
        path: '/my-organizations',
        name: 'my-organizations',
        component: MyOrganizationView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users-and-teams',
        name: 'users-and-teams',
        component: UsersAndTeams,
        props: route => ({q: route.query.q}),
        meta: {
            requiresAuth: true
        }
    },
    // Super-Admin
    {
        path: '/users-and-roles',
        name: 'users-and-roles',
        component: UsersAndRolesView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/organizations',
        name: 'organizations',
        component: Organizations,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/groups/:uuid',
        name: 'organization',
        component: Organization,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: Jobs,
        meta: {
            requiresAuth: true
        }
    },
];

const router = new VueRouter({
    routes
})


router.beforeEach((to, from, next) => {
    const currentUser = getAuth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth && !currentUser) next('login');
    else if (!requiresAuth && currentUser) next('home');
    else next();
});


export default router
