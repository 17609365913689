<template>
    <b-form-select
        v-model="value"
        :options="weekdays"
        @change="onChange"
        :class="value == null ? 'text-secondary' : 'text-primary'"
    />
</template>

<script>

export default {
  name: 'DayOfWeekSelector',
  props: {
    dayOfWeek: Number,
  },
  watch: {
    dayOfWeek: {
      handler() {
        this.value = this.dayOfWeek != null ? this.dayOfWeek : null;
      },
    },
  },
  data() {
    return {
      value: this.dayOfWeek != null ? this.dayOfWeek : null,
      weekdays: [
        {text: 'Sunday', value: '6'},
        {text: 'Monday', value: '0'},
        {text: 'Tuesday', value: '1'},
        {text: 'Wednesday', value: '2'},
        {text: 'Thursday', value: '3'},
        {text: 'Friday', value: '4'},
        {text: 'Saturday', value: '5'},
        {text: '(Not Set)', value: null},
      ],
    }
  },
  methods: {
    onChange: function (value) {
      this.$emit('change', value);
    },
  },
}
</script>
