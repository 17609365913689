<template>
  <b-container>
    <b-button @click="sync">Sync</b-button>
  </b-container>
</template>
<script>


export default {

  name: 'Test',
  components: {
  },
  props: {
    globalData: Object
  },

  data() {
    return {
      data: Object,
    }
  },
  created() {

  },
  destroyed() {
  }
  ,
  mounted() {
  },
  methods: {
    sync() {
          this.$http.get(`/api/workouts/sync`)
        .then(response => {
          this.data = response.data;
        })
        .catch(e => {
          console.log(e);
        })
    }
  }
}

</script>
<style scoped>

</style>
