<template>
  <b-card>
    <b-toast>
    </b-toast>
    <b-card-body>
      <p>
        To invite new users, click the <strong>Invite
        <font-awesome-icon icon="user-plus"/>
      </strong> button to generate an invitation link.
        Then, use the <strong>Copy Link
        <font-awesome-icon icon="link"/>
      </strong> button, and send it to the new users.
        The invitation link can be cancelled at any time.
      </p>
      <b-row
          class="no-select mt-0 mb-0 pb-2 pt-2 text-left"
          v-for="organization in adminOrganizations"
          :key="organization.id"
      >
        <b-col cols="8">
          <b-link :to="{ name: 'organization', params: { uuid: organization.uuid }}">{{ organization.name }}</b-link>
        </b-col>
        <b-col cols="4">
          <b-button
              v-if="organization.token == null"
              block
              size="sm"
              variant="outline-primary"
              @click="onInviteButton(organization)"
          >Invite
            <font-awesome-icon icon="user-plus"/>
          </b-button>

          <b-dropdown
              v-else
              split
              block
              variant="outline-success"
              size="sm"
              @click="onInviteButton(organization)"
          >
            <template slot="button-content">
              Copy Link
              <font-awesome-icon icon="link"/>
            </template>
            <b-dropdown-item-button
                size="sm"
                variant="danger"
                @click="onCancelInvite(organization)"><small>Cancel Invitation
              <font-awesome-icon icon="ban"/>
            </small></b-dropdown-item-button>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</template>

<script>

export default {
  name: 'OrganizationList',
  props: {
    globalData: Object,
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  computed: {
    adminOrganizations() {
      return Object.values(this.globalData.organizations)
          .filter(item => (item.role == 'Owner' || item.role == 'Admin'))
          .map(item => item.group);
    },
  },
  methods: {
    onInviteButton(organization) {
      if (organization.token == null) {
        this.$http.get(`/api/groups/${organization.uuid}/token`)
            .then(response => {
              console.debug(`Token: ${response.data}`);
              organization.token = response.data;
            })
            .catch(e => {
              console.log(e)
            })
      } else {
        this.$bvToast.toast('Invite Link Copied!', {
          toaster: 'b-toaster-bottom-center',
          autoHideDelay: 500,
          noCloseButton: true,
          variant: 'success'
        })
        let url = this.inviteUrl(organization);
        navigator.clipboard.writeText(url).then(function () {
        }, function () {
          // Failed
        });
      }
    },
    onCancelInvite(organization) {
      this.$http.delete(`/api/groups/${organization.uuid}/token`)
          .then(() => {
            organization.token = null;
          })
          .catch(e => {
            console.log(e)
          })
    },
    inviteUrl(organization) {
      return `${window.location.origin}/#/join?group=${organization.uuid}&token=${organization.token}`;
    },
  }
}
</script>
