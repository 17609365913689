<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Jobs</h2>
        <h5 class="text-secondary">System Admin</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="3">
        <b-button
            class="m-1"
            style="width: 220px;"
            v-for="job in data.jobs" :key="job.fqn"
            @click="onJobTypeClick(job)" size="sm">{{ job.name }}
        </b-button>
        <br/>
      </b-col>
      <b-col>
        <b-form-textarea
            style="font-family:monospace;"
            id="textarea"
            v-model="command"
            rows="10"
        ></b-form-textarea>
        <br/>
        <b-button @click="submitJob()" variant="success">Submit
        </b-button>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>

import ForbiddenBanner from "../../components/ForbiddenBanner";
import ForbiddenContentMixin from "../../components/ForbiddenContentMixin";

export default {
  name: 'Jobs',
  mixins: [ForbiddenContentMixin],
  components: {
    ForbiddenBanner,
  },
  data() {
    return {
      data: [],
      command: "",
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$http.get(`/api/sa/jobs-types`)
          .then(response => {
            this.forbidden = false;
            this.data = response.data
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
    onJobTypeClick(job) {
      this.command = JSON.stringify({
        fqn: job.fqn,
        kwargs: job.args,
      }, undefined, 2);
    },
    submitJob() {
      this.$http.post(`/api/sa/submit-job`, this.command)
          .then(response => {
            console.log(response);
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
  }
}
</script>

<style scoped>
</style>
