<script>

export default {
  name: 'LabelList',
  props: {
    globalData: Object,
    selectedLabelUuids: {
      default() {
        return []
      },
      type: Array,
    },
    readOnly: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      labels: []
    }
  },
  methods: {
    getLabelColor: function (label) {
      return this.selectedLabelUuids.includes(label.uuid) ? label.color : label.color + '50';
    },
    getLabelIcon: function (label) {
      return this.selectedLabelUuids.includes(label.uuid) ? "check-square" : "square";
    },
    onLabelChange: function (label, value) {
      if (this.readOnly) {
        return;
      }

      if (value) {
        this.selectedLabelUuids.push(label.uuid);
      } else {
        const index = this.selectedLabelUuids.indexOf(label.uuid);
        if (index > -1) {
          this.selectedLabelUuids.splice(index, 1);
        }
      }
      this.selectedLabelUuids.sort(function (a, b) {
        return a.id - b.id;
      });
      this.updateSelected();
    },
    updateSelected: function () {
      let labelUuids = this.selectedLabelUuids;
      console.log(`Selected labels: ${labelUuids}`)
      this.$emit('selected-labels-changes', labelUuids);
    }
  }
}
</script>

<style>
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>