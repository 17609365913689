<template>
  <svg width="100%" :height="height" :viewBox="`0 0 ${width} ${height}`">
    <rect width="100%" height="100%" fill="#f8f8f8"/>
    <g :transform="`translate (${pad},${(height-pad)}) scale(1, -1)`">
      <!-- Dashed, color lines for each zone-->
      <polyline
          v-for="index in [1,2,3,4,5]"
          :key="`zone-${index}`"
          :transform="`translate (0,
          ${index * yTick})`"
          :points="`0,0 ${width-2*pad},0 `"
          class="zone-line"
          :class="`zone${index}`"
      />

      <!-- 15 minute tick marks -->
      <polyline
          v-for="index in xTicks"
          :key="`tick-${index}`"
          :transform="`translate (${index * scale * 900} 0)`"
          :points="`0,-8 0,0`"
          class="axis"
      />
      <!-- X & Y axes -->
      <polyline class="axis" :points="`0,0 ${width - (2 * pad)},0`"/>
      <polyline class="axis" :points="`0,0 0,${height - (2 * pad)}`"/>

      <!-- Workout plan path  -->
      <polyline
          :points="svgPoints"
          fill="none"
          stroke="black"
          stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>

export default {

  name: 'WorkoutSvgContents',
  components: {},
  props: {
    duration: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 482,   // This value is tied to the "class="d-none d-xl-block" in WorkoutCard in Workout.vue
    },
    height: {
      type: Number,
      default: 75,
    },
    pad: {
      type: Number,
      default: 10
    },
  },
  data() {
    return {}
  },
  computed: {
    yTick: function () {
      return (this.height - 3 * this.pad) / [1, 2, 3, 4, 5].length;
    },
    xTicks: function() {
      // Tick per 15 minutes (900 seconds)
      return [...Array(Math.ceil(this.duration / 900)).keys()];
    },
    scale: function () {
      return (this.width - (2 * this.pad)) / this.duration;
    },
    svgPoints: function () {
      let points = []
      this.processSteps(points, this.steps, 0);
      return points.map(
          point => `${point.x1}, ${point.y} ${point.x2}, ${point.y} `
      ).join('')
    },
  },
  methods: {
    processSteps: function (timeline, steps, offset) {
      for (let step of steps) {
        if (step.type == 'WorkoutStep') {
          if (step.durationType == 'TIME') {
            timeline.push({
              x1: offset * this.scale,
              x2: (offset + step.durationValue) * this.scale,
              y: step.zones[step.zones.length - 1] * this.yTick,
            });
            offset += step.durationValue;
          }
        } else if (step.type == 'WorkoutRepeatStep') {
          for (let i = 0; i < step.repeatValue; i++) {
            offset = this.processSteps(timeline, step.steps, offset);
          }
        }
      }
      return offset;
    }
  }
}
</script>

<style scoped>

.axis {
  fill: none;
  stroke-width: 0.5;
  stroke: #00000080;
}

.zone-line {
  fill: none;
  opacity: 0.4;
  stroke-width: 1;
  stroke-dasharray: 0 3 2 4;
}
</style>
