<template>
  <span>
    <!-- Collapse with textual explanation about the roles -->
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Users and Roles"></b-form-input>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        :items="data"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
    >
      <template #cell(role)="data">
        <h5>
          <b-badge :variant="roleToVariant(data.item.role)">{{ data.item.role }}
          </b-badge>
        </h5>
      </template>
      <template #cell(groupType)="data">
        <h5>
          <b-badge :variant="groupTypeToVariant(data.item.groupType)">{{ data.item.groupType }}
          </b-badge>
        </h5>
      </template>
      <template #cell(actions)="data">
        <b-button
            v-if="isSuperAdmin && (data.item.username !== globalData.activeUser.username)"
            size="sm"
            title="Impersonate"
            variant="outline-danger"
            @click="onImpersonate(data.item)">
          <font-awesome-icon icon="mask"/>
        </b-button>
      </template>

    </b-table>
  </span>
</template>

<script>

export default {
  name: 'UsersAndRolesTable',
  data() {
    return {
      sortBy: 'role',
      sortDesc: false,
      fields: [
        {key: "name", sortable: true, class: "text-truncate"},
        {key: "username", sortable: true, class: "text-truncate"},
        {key: "role", sortable: true, class: "text-truncate"},
        {key: "groupName", sortable: true, class: "text-truncate"},
        {key: "groupType", sortable: true, class: "text-truncate"},
        {key: "actions", label: "", class: "d-none d-md-table-cell"},
      ],
      filter: '',
    }
  },
  props: {
    data: Array,
    globalData: Object,
    isSuperAdmin: {
      type: [Boolean, Array],
      default: false,
    },
  },
  methods: {
    roleToVariant(role) {
      switch (role) {
        case 'SuperAdmin':
          return 'dark'
        case 'Owner':
          return 'danger'
        case 'Admin':
          return 'warning'
        case 'Coach':
          return 'success'
        case 'User':
          return 'primary'
      }
      return 'light'
    },
    groupTypeToVariant(groupType) {
      switch (groupType) {
        case 'Organization':
          return 'primary'
        case 'Team':
          return 'success'
      }
      return 'light'
    },
    onImpersonate: function (user) {
      this.$http.get(`/api/sa/users/${user.uuid}/debug`)
          .then(response => {
            console.error(response.data);
          })
          .catch(e => {
            console.warn(e)
          })


      console.info(`Impersonating to ${user.uuid}`)
      this.$http.post(`/api/sa/impersonate`,
          {},
          {
            headers: {
              'Impersonate-User': user.uuid,
            }
          })
          .then(() => {
            this.$emit('active-user-changed');
            this.$router.push({name: 'profile'});
          })
          .catch(e => {
            console.warn(e)
          })
    },
  }
}
</script>
