<template>
  <b-container class="login">
    <b-card title="Welcome To Runner's Eye" sub-title="" align="center">
      <b-card-text>
        <h6>Sign In with Google</h6>
        <button @click="socialLogin" class="social-button">
          <img alt="Google Logo" src="../assets/google-logo.png">
        </button>
      </b-card-text>
    </b-card>
  </b-container>
</template>

<script>
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login() {
      getAuth().signInWithEmailAndPassword(this.email, this.password).then(() => {
        this.$router.replace('home');
      }).catch((err) => {
        alert('Oops. ' + err.message)
      });
    },
    socialLogin() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(getAuth(), provider).then(() => {
        this.$router.push({name: 'profile'});
      }).catch((err) => {
        alert('Oops. ' + err.message)
      });
    }
  }
}
</script>

<style scoped>  /* "scoped" attribute limit the CSS to this component only */
.login {
  margin-top: 40px;
}

input {
  margin: 10px 0;
  width: 20%;
  padding: 15px;
}

button {
  margin-top: 20px;
  width: 10%;
  cursor: pointer;
}

p {
  margin-top: 40px;
  font-size: 13px;
}

p a {
  text-decoration: underline;
  cursor: pointer;
}

.social-button {
  width: 75px;
  background: white;
  padding: 10px;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
  border: 0;
}

.social-button:active {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.social-button img {
  width: 100%;
}
</style>