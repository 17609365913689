<template>
  <b-jumbotron
      header="Forbidden"
      header-level="5"
      lead="Sorry, this data is not for your eyes :("
  />
</template>
<script>

export default {
  name: 'ForbiddenBanner',
}
</script>