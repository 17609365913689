<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Activities</h2>
        <h5 class="text-secondary">{{ user.name }} ({{ user.username }})</h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Activities"></b-form-input>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        sort-by="startTime"
        :sort-desc="true"
        :items="activities"
        :fields="fields"
        :filter="filter"
    >
      <template #cell(startTime)="data">
        {{ formatDate(data.item.startTime) }}
      </template>

      <template #cell(duration)="data">
        <Duration v-bind:duration="data.item.duration"/>
      </template>

      <template #cell(distance)="data">
        <Distance v-bind:value="data.item.distance"/>
      </template>

      <template #cell(heartRate)="data">
        {{ data.item.heartBeatAvg }} / {{ data.item.heartBeatMax }}
      </template>

      <template #cell(device)="data">
        <font-awesome-icon
            icon="heartbeat"
            size="1x"
            :title="data.item.device"
        />
      </template>

    </b-table>
  </b-container>
</template>

<script>

import Duration from "../components/Duration";
import Distance from "../components/Distance";
import ForbiddenBanner from "../components/ForbiddenBanner";
import ForbiddenContentMixin from "../components/ForbiddenContentMixin";
import moment from 'moment'

export default {
  name: 'UserActivities',
  mixins: [ForbiddenContentMixin],
  components: {
    Duration,
    Distance,
    ForbiddenBanner,
  },
  data() {
    return {
      user: Object,
      activities: [],
      fields: [
        {key: "name", label: "Activity", class: "text-truncate"},
        {key: "type"},
        {key: "startTime", label: 'Date'},
        {key: "duration"},
        {key: "distance"},
        {key: "heartRate"},
        {key: "device"},
      ],
      filter: '',
    }
  },
  props: {
    uuid: String,
  },
  created() {
    this.refresh();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(value).format('ll');
      }
    },
    refresh() {
      this.$http.get(`/api/users/${this.uuid}`)
          .then(response => {
            this.forbidden = false;
            this.user = response.data
            this.$http.get(`/api/activities?userUuid=${this.uuid}`)
                .then(response => {
                  this.forbidden = false;
                  response.data.forEach(item => {
                    item.startTime = moment(item.startTime).valueOf();
                  })
                  this.activities = response.data;
                })
                .catch(e => {
                  this.HandleResponse(e);
                })
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
