<template>
  <span :class="textClass">
    <font-awesome-icon
        icon="clock"
        size="1x"
        v-if="showIcon"
        :class="editable ? 'clickable': ''"
        @click="onClickIcon"
    />
    <span id="text"> {{ formatted }}</span>
  </span>
</template>

<script>

export default {
  name: 'Duration',
  props: {
    showIcon: {
      type: Boolean,
      default: true
    },
    duration: Number,
    textClass: {
      type: String,
      default: "h6"
    },
  },
  data() {
    return {
      editable: Boolean,
    }
  },
  computed: {
    hours() {
      return Math.floor(this.duration / 3600);
    },
    minutes() {
      return Math.floor(this.duration / 60) % 60;
    },
    seconds() {
      return this.duration % 60;
    },
    formatted() {
      const h = this.duration >= 3600 ? `${this.pad(this.hours)}:` : '';
      return `${h}${this.pad(this.minutes)}:${this.pad(this.seconds)}`
    },
  },
  methods: {
    pad(value) {
      return `${value > 9 ? '' : '0'}${value}`;
    },
    onClickIcon(event) {
      this.$emit('clicked', event)
    }

  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
