<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Organization</h2>
        <h5 class="text-secondary">{{ organization.name }}</h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Teams"></b-form-input>
      </b-col>
    </b-row>
    <br/>
    <b-table
        hover
        :items="teams"
        :fields="fields"
        :filter="filter"
    >
<!--      <template #cell(name)="data">-->
<!--        <b-link :to="{ name: 'group', params: { uuid: data.item.uuid }}">{{ data.item.name }}</b-link>-->
<!--      </template>-->
    </b-table>
    <b-table
        hover
        :items="users"
    >
<!--      <template #cell(name)="data">-->
<!--        <b-link :to="{ name: 'group', params: { uuid: data.item.uuid }}">{{ data.item.name }}</b-link>-->
<!--      </template>-->
    </b-table>

  </b-container>
</template>

<script>

import ForbiddenBanner from "../../components/ForbiddenBanner";
import ForbiddenContentMixin from "../../components/ForbiddenContentMixin";

export default {
  name: 'Organization',
  mixins: [ForbiddenContentMixin],
  components: {
    ForbiddenBanner,
  },
  props: {
    uuid: String,
  },
  data() {
    return {
      organization: Object,
      teams: Array,
      users: Array,
      fields: [
        {key: "name", sortable: true, class: "text-truncate"},
        {key: "lastModified", sortable: true, class: "text-truncate"},
      ],
      filter: '',
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$http.get(`/api/groups/${this.uuid}`)
          .then(response => {
            this.organization = response.data.organization;
            // remove the role from the returned data structure
            this.teams = response.data.teams.map(item => item['group']);
            this.users = response.data.users;
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
  }
}
</script>

<style scoped>
</style>
