<template>
  <b-card
      class="workout-card text-left m-1 mt-2 mb-2"
      border-variant="secondary"
      :body-class="compact ? 'pl-1 pr-1 pt-1 pb-0' : ''"
  >
    <b-overlay :show="showOverlay" rounded="sm">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-card>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
        </template>
        <b-card-title
            title-tag="h6"
            :class="compact ? 'mt-1 mb-0' : ''"
        >
          <b-row>
            <b-col
                class="text-truncate"
                cols="8">
              <b-link v-if="showLink" :to="{ name: 'workout', params: { uuid: uuid }}">{{ name }}</b-link>
              <span v-else>{{ name }}</span>
            </b-col>
            <b-col class="text-right" cols="4">
              <b-button
                  v-if="! compact"
                  size="sm"
                  v-on:click="publish"
                  variant="link"
              >Publish
              </b-button>
              <Duration v-if="compact" v-bind:duration="meta.duration" v-bind:text-class="'small'"/>
            </b-col>
          </b-row>
        </b-card-title>
        <b-card-body
            class="p-1 "
            :class="compact ? 'ml-0 mr-0' : ''"
        >
          <WorkoutContents :steps="data.steps" v-if="! compact"></WorkoutContents>
          <b-row class="pt-1  " v-if="compact">
            <b-col>
              <WorkoutSvgContent :steps="data.steps" :duration="meta.duration"></WorkoutSvgContent>
            </b-col>
          </b-row>
          <b-row class="pt-1" v-if="compact">
            <b-col class="text-left">
              <Duration v-if="false" v-bind:duration="meta.duration" v-bind:text-class="'h6'"/>
            </b-col>
            <b-col class="text-right">
              <Distance v-if="meta.distance > 0" v-bind:value="meta.distance" v-bind:text-class="'h6'"/>
            </b-col>
          </b-row>
        </b-card-body>
      </b-skeleton-wrapper>

    </b-overlay>
  </b-card>

</template>

<script>

import WorkoutContents from "@/components/Workouts/WorkoutContents";
import WorkoutSvgContent from "@/components/Workouts/WorkoutSvgContent";
import Duration from "@/components/Duration";
import Distance from "@/components/Distance";

export default {

  name: 'WorkoutCard',
  components: {
    WorkoutContents,
    WorkoutSvgContent,
    Distance,
    Duration,
  },
  props: {
    uuid: String,
    compact: Boolean,
    showLink: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      data: [],
      meta: Object,
      name: '',
      showOverlay: false,
      loading: true,
    }
  },
  created() {
    this.$http.get(`/api/workouts/${this.uuid}.json`)
        .then(response => {
          this.data = response.data.workout;
          this.name = response.data.name;
          this.meta = response.data.meta;
          this.loading = false;
        })
        .catch(() => {
          // console.warn(e)
        });

    let vm = this;
    this.$parent.$on('updateWorkout', function (data) {
      vm.data = data.workout;
      vm.meta = data.meta;
      vm.name = data.name;
    });
  },
  methods: {
    publish: function () {
      this.showOverlay = true;
      this.$http.get(`/api/workouts/${this.uuid}/publish`)
          .then(() => {
            this.showOverlay = false;
          })
          .catch(() => {
            // console.warn(e)
            this.showOverlay = false;
          })
    },
  }
}
</script>

<style scoped>
</style>
