<template>
  <b-container>
    <b-row class="text-left">
      <b-col>
        <h2>Labels</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="sitemap" size="1x"/>
          {{ globalData.currentOrganization.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Labels"></b-form-input>
      </b-col>
      <b-col cols="4">
      </b-col>
      <b-col cols="3" align-h="end" class="text-right">
        <b-button variant="success" lass="text-right" @click="onCreateLabel">Create</b-button>
      </b-col>
    </b-row>
    <br/>

    <b-table
        class="text-left"
        hover
        :items="labels"
        :fields="fields"
        :filter="filter"
    >
      <template #cell(name)="data">
        <b-row>
          <b-col cols="3" sm="2" lg="1">
            <b-form-input
                :disabled="! hasAdminAccessToOrganization(data.item.owningGroupUuid)"
                v-model="data.item.color"
                type="color"
                @change="onUpdateLabel(data.item)"
            />
          </b-col>
          <b-col cols="8" sm="6" md="5" lg="4">
            <b-form-input
                :readonly="! hasAdminAccessToOrganization(data.item.owningGroupUuid)"
                v-model="data.item.text"
                @change="onUpdateLabel(data.item)"
            />
          </b-col>
        </b-row>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import GlobalDataMixin from "@/components/GlobalDataMixin";

export default {

  name: 'LabelView',
  mixins: [GlobalDataMixin],
  data() {
    return {
      filter: "",
      fields: [
        {key: 'name', label: 'Label'},
      ],
      labels: [],
    }
  },
  props: {
    globalData: Object
  },
  watch: {
    globalData: {
      handler() {
        this.refresh();
      },
      deep: true
    },
  }, created() {
    this.refresh();
  },
  methods: {
    refresh: function () {
      this.$http.get(`/api/labels`)
          .then(response => {
            this.labels = response.data
          })
          .catch(e => {
            console.warn(e)
          })
    },
    onCreateLabel: function () {
      this.$http.post(`/api/labels`)
          .then(() => {
            this.refresh();
          })
          .catch(e => {
            console.warn(e)
          })
    },
    onUpdateLabel: function (item) {
      console.log(item.text);
      console.log(item.color);
      this.$http.post(`/api/labels/${item.uuid}`, item)
          .then(() => {
            this.refresh();
          })
          .catch(e => {
            console.warn(e)
          })
    },
  }
}
</script>

<style scoped>
</style>
