<template>
  <b-container>
    <b-row class="text-left">
      <b-col>
        <h2>Plans</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="sitemap" size="1x"/>
          {{ globalData.currentOrganization.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Plans"></b-form-input>
      </b-col>
    </b-row>
    <br/>

    <b-table
        hover
        :items="plans"
        :fields="fields"
        :filter="filter"
        class="text-left">

      <template #cell(name)="data">
        <router-link :to="{ name: 'assign-workouts', params: { uuid: data.item.uuid }}">{{
            data.item.name
          }}
        </router-link>
      </template>

      <template #cell(active)="data">
        <b-form-checkbox
            v-model="data.item.active"
            @input="onTogglePlanState(data.item)"
        >
        </b-form-checkbox>
      </template>

      <template #cell(timestamp)="data">
        {{ timestampFormatter.format(data.item.timestamp) }}
      </template>

      <template #cell(slots)="data">
        {{ data.item.slots.length }}
      </template>

      <template #cell(actions)="">
        <b-col cols="6">
          <b-button size="sm">Edit</b-button>
        </b-col>
      </template>

      <template #cell(actions)="data">
        <b-button
            size="sm"
            variant="outline-dark"
            :to="{ name: 'plan', params: { uuid: data.item.uuid }}">
          Schedule
          <font-awesome-icon icon="calendar-alt" size="1x"/>
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>

export default {

  name: 'PlanList',

  props: {
    globalData: Object
  },
  watch: {
    globalData: {
      handler() {
        this.refresh();
      },
      deep: true
    },
  },
  data() {
    return {
      filter: "",
      plans: [],
      fields: [
        {key: "name", label: "Plan Name", class: "text-truncate"},
        {key: 'timestamp', sortable: true, label: 'Modified', class: "d-none d-md-table-cell"},
        {key: "active"},
        {key: "slots"},
        {key: "actions", label: ''},
      ],
      timestampFormatter: new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }),
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh: function () {
      this.$http.get(`/api/plans`)
          .then(response => {
            this.plans = response.data
            response.data.forEach(item => {
              item.timestamp = Date.parse(item.lastModified)
            })
          })
          .catch(e => {
            console.error(e)
          })
    },
    onTogglePlanState: function (plan) {
      this.$http.post(`/api/plans/${plan.uuid}/activate`, {active: plan.active})
          .then(() => {
          })
          .catch(e => {
            console.warn(e)
          })
    },
  },
}
</script>
<style scoped>
.container {
  margin-top: 30px;
}
</style>
