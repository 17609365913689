<script>

export default {
  name: 'GlobalDataMixin',
  data() {
    return {
      forbidden: false
    }
  },
  computed: {
    isAdmin() {
      if (this.globalData == null || this.globalData.currentOrganization == null) {
        return false;
      }
      return this.hasAdminAccessToOrganization(this.globalData.currentOrganization.uuid);
    },
    isSuperAdmin() {
      return this.globalData ? this.globalData.activeUser.roles ?
          this.globalData.activeUser.roles['SuperAdmin'] != null : false : false;
    },
  },
  methods: {
    hasAdminAccessToOrganization(organizationUuid) {
      if (!this.globalData || !this.globalData.activeUser.roles) {
        return false;
      }
      if (this.globalData.activeUser.roles['SuperAdmin']) {
        return true;
      }
      if (this.globalData.activeUser.roles['Owner'] && this.globalData.activeUser.roles['Owner'].includes(organizationUuid)) {
        return true;
      }
      if (this.globalData.activeUser.roles['Admin'] && this.globalData.activeUser.roles['Admin'].includes(organizationUuid)) {
        return true;
      }

      return false;
    },
  }
}
</script>
