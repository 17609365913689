<template>
  <b-container v-if="forbidden">
    <ForbiddenBanner/>
  </b-container>
  <b-container v-else>
    <b-row class="text-left">
      <b-col>
        <h2>Runners</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="users" size="1x"/>
          {{ globalData.currentTeam.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Runners"/>
      </b-col>
    </b-row>
    <br/>
    <b-table hover :items="workouts" :fields="fields" :filter="filter">
      <template #head(thresholdPace)="data">
        <span class="d-none d-sm-block">{{ data.label }}</span>
        <span class="d-block d-sm-none">Pace</span>
      </template>

      <template #cell(name)="data">
        <b-form-checkbox
            v-model="data.item.enabled"
            @input="onToggleUserState(data.item)"
        >
          <router-link :to="{ name: 'users-and-teams', query: { q: data.item.username }}">{{
              data.item.name
            }}
          </router-link>

        </b-form-checkbox>
      </template>
      <template #cell(labels)="data">
        <LabelSelector
            :globalData="globalData"
            :readOnly=true
            :selectedLabelUuids="data.item.data.labelUuids"
        />
      </template>
      <template #cell(thresholdPace)="data">
        <Duration v-bind:duration="data.item.data.thresholdPace.minutes * 60 +data.item.data.thresholdPace.seconds "/>
      </template>
      <template #cell(activities)="data">
        <router-link :to="{ name: 'user-activities', params: { uuid: data.item.uuid }}">
          View
        </router-link>
      </template>
    </b-table>
  </b-container>
</template>

<script>

import Duration from "../components/Duration";
import LabelSelector from "../components/Labels/LabelSelector";
import ForbiddenBanner from "../components/ForbiddenBanner";
import ForbiddenContentMixin from "../components/ForbiddenContentMixin";

export default {
  name: 'Runners',
  mixins: [ForbiddenContentMixin],
  components: {
    Duration,
    ForbiddenBanner,
    LabelSelector,
  },
  data() {
    return {
      workouts: [],
      fields: [
        {key: "name", label: "Name", class: "text-truncate"},
        {key: "labels", class: "d-none d-sm-table-cell"},
        {key: "username", label: "Email", class: "d-none d-md-table-cell"},
        {key: "thresholdPace"},
        {key: "activities",},
      ],
      filter: '',
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$http.get(`/api/users`)
          .then(response => {
            this.forbidden = false;
            this.workouts = response.data
          })
          .catch(e => {
            this.HandleResponse(e);
          })
    },
    onToggleUserState: function (user) {
      let userUuid = user.uuid;
      let enabled = user.enabled;
      console.log(`${userUuid} -> ${enabled}`);

      this.$http.post(`/api/users/${userUuid}/enable`, {enabled: enabled})
          .then(() => {
          })
          .catch(e => {
            console.warn(e)
          })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
