<template>
  <b-container>
    <b-row class="text-left">
      <b-col>
        <h2>Workouts</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="sitemap" size="1x"/>
          {{ globalData.currentOrganization.name }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="border shadow-lg user-select-none bg-light text-left mt-2 pt-2 pb-2" align-v="center">
      <b-col md="7" lg="5">
        <b-form-input v-model="filter" placeholder="Search Workouts"></b-form-input>
      </b-col>
      <b-col cols="4">
        <b-form-checkbox class="text-right" v-model="showInactive" size="lg" switch @change="onShowInactiveChange()">
          Show inactive
        </b-form-checkbox>
      </b-col>
      <b-col cols="3" align-h="end" class="text-right">
        <b-button variant="success" lass="text-right" @click="onCreateWorkout">Create</b-button>
      </b-col>
    </b-row>
    <br/>

    <b-table
        class="text-left"
        hover
        :items="filteredWorkouts"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
    >
      <template #cell(name)="data">
        <router-link :to="{ name: 'workout', params: { uuid: data.item.uuid }}">{{
            data.item.name
          }}
        </router-link>


      </template>
      <template #cell(labels)="data">
        <LabelSelector
            :globalData="globalData"
            :readOnly=true
            :selectedLabelUuids="data.item.meta.labelUuids ? data.item.meta.labelUuids : []"
        />
      </template>
      <template #cell(timestamp)="data">
        {{ timestampFormatter.format(data.item.timestamp) }}

      </template>
      <template #cell(actions)="data">
        <b-button
            size="sm"
            :variant="data.item.active ? 'outline-dark' : 'outline-success'"
            @click="onActivateWorkout(data.item)">{{ data.item.active ? 'Archive' : 'Restore' }}
          <font-awesome-icon
              :icon="data.item.active ? 'trash-alt' : 'trash-restore-alt'"
              size="1x"
          />
        </b-button>
      </template>
    </b-table>
  </b-container>

</template>

<script>

import LabelSelector from "../components/Labels/LabelSelector";

export default {

  name: 'Workouts',
  components: {
    LabelSelector,
  },
  data() {
    return {
      filter: "",
      showInactive: false,
      sortBy: 'timestamp',
      sortDesc: true,
      fields: [
        {key: 'name', sortable: true, label: 'Workout Name'},
        {key: "labels", class: "d-none d-sm-table-cell"},
        {key: 'timestamp', sortable: true, label: 'Modified', class: "d-none d-md-table-cell"},
        {key: 'actions', sortable: false, label: ''},
      ],
      workouts: [],
      timestampFormatter: new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }),
    }
  },
  props: {
    globalData: Object
  },
  watch: {
    globalData: {
      handler() {
        this.refresh();
      },
      deep: true
    },
  },
  computed: {
    filteredWorkouts: function () {
      let self = this;
      if (!this.workouts) {
        return [];
      }
      return this.workouts.filter(function (workout) {
        return (workout.active || self.showInactive) && workout.name.toUpperCase().indexOf(self.filter.toUpperCase()) >= 0;
      })
    }
  }, created() {
    this.refresh();
  },
  methods: {
    onShowInactiveChange() {
      this.refresh();
    },
    refresh: function () {
      this.$http.get(`/api/workouts`, {params: {'showInactive': this.showInactive}})
          .then(response => {
            // JSON responses are automatically parsed.
            this.workouts = response.data
            response.data.forEach(item => {
              item.timestamp = Date.parse(item.lastModified)
            })
          })
          .catch(e => {
            console.warn(e)
          })
    },

    onCreateWorkout: function () {
      this.$http.post(`/api/workouts/create`)
          .then(() => {
            this.refresh();
          })
          .catch(e => {
            console.warn(e)
          })
    },
    onActivateWorkout: function (workout) {
      let workoutUuid = workout.uuid;
      let active = !workout.active;

      this.$http.post(`/api/workouts/${workoutUuid}/activate`, {active: active})
          .then(() => {
            workout.active = active;
          })
          .catch(e => {
            console.warn(e)
          })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
