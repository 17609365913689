<template>
  <b-container>
    <b-jumbotron
        v-cloak
        class="mt-4" :lead="lead">
      <template slot="header">
        <h3>{{ header }}</h3>
      </template>
      <router-link :to="{ name: 'profile'}">
        <b-button variant="primary">Go to my Profile</b-button>
      </router-link>
    </b-jumbotron>
  </b-container>
</template>

<script>

export default {

  name: 'Join',
  props: {
    globalData: Object
  },

  data() {
    return {
      header: String,
      lead: String,
    }
  },
  created() {
    this.header = '';
    this.lead = ''
  },
  mounted() {
    this.$http.get(`/api/groups/${this.$route.query.group}/join?token=${this.$route.query.token}`)
        .then(response => {
          this.$emit('active-user-changed');
          let groupName = response.data.name;
          this.header = `Welcome to ${groupName}!`
          this.lead = 'You are one of us now :)'
        })
        .catch(e => {
          this.header = "Oops..."
          this.lead = "There was a problem, try talking to your coach"
          console.log(e);
        })
  }
  ,
}
</script>
