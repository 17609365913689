<template>
  <b-jumbotron class="home">
    Runner's Eye
    <br/>
    <br/>
    <button @click="run">Go!</button>
    <br/>
    <button @click="$bvToast.show('global-toast')">Close!</button>


    <b-progress :value="index" :max="total" class="mb-3"></b-progress>
    <span
        v-for="zone in zones"
        :key="zone"
    >
       {{ zone }} </span>
    <b-row class="text-center">
      <b-col>
        <Distance
            read-only
            value=10000
            text-class="h1"
        />
      </b-col>
    </b-row>
  </b-jumbotron>
</template>

<script>
// @ is an alias to /src

import {getAuth} from 'firebase/auth';
import Distance from "@/components/Distance";

export default {
  name: 'Home',
  data() {
    return {
      index: 0,
      total: 100,
      connection: null,
      zones: [1, 2, 3, 4, 5],
    }
  },
  components: {Distance},
  methods: {
    close: function () {
      // this.connection.close();
    },
    run: function () {

      this.$http.get(`/api/workouts/sync`)
          .then(() => {


            let self = this;
            if (self.connection != null) {
              self.connection.close();
            }

            // TODO: refresh when expired
            getAuth().currentUser.getIdToken(false).then(function (idToken) {
              // Connect to WS
              console.debug("Starting connection to WebSocket Server")
              let loc = window.location;
              let protocol = loc.protocol === "https:" ? "wss:" : "ws:";
              let ws_uri = `${protocol}//${loc.hostname}/api/ws?id_token=${idToken}`;
              self.connection = new WebSocket(ws_uri)

              self.connection.onmessage = function (message) {
                let data = JSON.parse(message.data)
                // self.notifications = data.counter % 2;
                // console.log(data.progress);
                self.index = data.progress[0];
                self.total = data.progress[1];
              }

              self.connection.onopen = function (event) {
                console.debug(event)
                console.debug("WebSocket Opened")
              }

              self.connection.onclose = function (event) {
                // TODO: retry connect
                console.debug(event)
                console.debug("WebSocket Closed")
              }
            })
                .catch(e => {
                  console.warn(e)
                })

          })
          .catch(e => {
            console.warn(e)
          })
    }
  }
}
</script>
