<template>
  <b-container>
    <b-row class="text-left">
      <b-col>
        <h2>Organizations</h2>
        <h5 class="text-secondary">{{ globalData.activeUser.name }}</h5>
      </b-col>
    </b-row>
    <br/>
    <b-row>
      <b-col cols="6">
        <OrganizationList :globalData="globalData"/>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import GlobalDataMixin from "@/components/GlobalDataMixin";
import OrganizationList from "@/components/Admin/OrganizationList";

export default {

  name: 'MyOrganizationView',
  mixins: [GlobalDataMixin],
  components: {OrganizationList},
  data() {
    return {}
  },
  props: {
    globalData: Object
  },
}
</script>

<style scoped>
</style>
