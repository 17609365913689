<template>
  <b-row>
    <b-col :class="textClass">
      <font-awesome-icon
          id="icon"
          icon="route"
          class="d-none d-md-inline"
          :class="readOnly ? '': 'clickable'"
          @click="onClickIcon"
      />
      <span
          id="text"
          v-if="! edit"
          class="noselect text-truncate"
          @dblclick="onToggleEdit(true)"
      > {{ formatted }} km</span>
      <b-form-input
          id="input"
          ref="value"
          v-if="edit"
          size="sm"
          v-model="value"
          @focusout="onToggleEdit(false)"
          @keydown.native="onKeyDown"
      />
    </b-col>
  </b-row>


</template>

<script>

export default {
  name: 'Distance',
  props: {
    // Distance, in meters
    value: Number,
    readOnly: Boolean,
    textClass: {
      type: String,
      default: "h6"
    },
  },
  data() {
    return {
      edit: Boolean,
    }
  },
  created() {
    this.edit = false;
  },
  computed: {
    kilometers() {
      return (this.value / 1000).toFixed(1);
    },
    formatted() {
      return `${this.kilometers}`
    },
  },
  methods: {
    onClickIcon(event) {
      this.$emit('clicked', event)
    },
    onKeyDown(event) {
      if (event.which === 13) {
        this.edit = false;
      }
    },
    onToggleEdit(value) {
      if (!this.readOnly) {
        this.edit = value;
        if (value) {
          this.$refs.value.focus();
        }
      }
    }
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

#text {
  margin-left: 5px;
}

#input {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-align: center;
  height: 22px;
  width: 50px;
  padding: 0px;
  margin-top: 1px;
  margin-left: 5px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
