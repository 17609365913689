<template>
  <span>
      <template v-for="workoutStep in steps">
        <WorkoutStep :data="workoutStep" v-if="workoutStep.type == 'WorkoutStep'" :key="workoutStep.stepOrder"
                     class="workout-step pl-1"/>
        <template v-else-if="workoutStep.type == 'WorkoutRepeatStep'">
          <WorkoutRepeatStep :data="workoutStep" class="workout-step pl-1" :key="workoutStep.id"/>
          <WorkoutStep v-for="innerStep in workoutStep.steps" :data="innerStep" :key="innerStep.id"
                       :parent="workoutStep" class="repeat-step pl-1"/>
        </template>
      </template>
  </span>
</template>

<script>

import WorkoutStep from "@/components/Workouts/WorkoutStep";
import WorkoutRepeatStep from "@/components/Workouts/WorkoutRepeatStep";

export default {

  name: 'WorkoutContents',
  components: {
    WorkoutStep,
    WorkoutRepeatStep
  },
  props: {
    steps: Array,
    compact: Boolean
  },
  data() {
    return {}
  },
}
</script>

<style scoped>

.workout-step {
  border-left: transparent solid 5px;
}

.repeat-step {
  border-left: black solid 4px;
  background-color: #e4f8f8;
}
</style>
