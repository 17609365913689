<template>
  <b-row class="m-0 p-0">
    <b-col cols="2" class="p-0 d-none d-lg-block 1">
    </b-col>
    <b-col cols="2" class="p-0 d-none d-lg-block">#{{ data.stepOrder }}</b-col>
    <b-col cols="5" class="p-0 lp-1">
      <Repeats :repetitions="data.repeatValue"/>
    </b-col>
    <b-col cols="3" class="p-0">
    </b-col>

  </b-row>
</template>

<script>

import Repeats from "@/components/Workouts/Repeats";

export default {
  name: 'WorkoutRepeatStep',
  components: {
    Repeats
  },
  props: {
    data: Object
  },
}
</script>

<style scoped>

</style>
